import React from 'react'

const LittleTextModule = ({ title, text, primary, secondary }) => (
  <div className="bg-primary white" style={{background: `linear-gradient(135deg, #${ primary }, #${ secondary })` }}>
    <div className="mw7-m mw8-l center ph3 pv4 pv6-ns">
      <div className="cf mh-2-m mh-3-l">
        <div className="fl w-100 ph2-m ph3-l">
          <h3 className="mt0 normal">{ title }</h3>
        </div>
        <div className="fl w-100 ph2-m ph3-l f4-ns" dangerouslySetInnerHTML={{__html: text}}>
        </div>
      </div>
    </div>
  </div>
)

export default LittleTextModule
