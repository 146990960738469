import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const useFrontmatters = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query ClientsOrderQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___order] }
        filter: { frontmatter: { layout: { eq: "project" } } }
      ) {
        edges {
          node {
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              path
              title
              client {
                name
              }
              layout
              order
              tags {
                name
              }
            }
          }
        }
      }
    }
  `)
  return allMarkdownRemark.edges.map(d => d.node.frontmatter)
}

const getNavigation = (projects, order) =>
  projects.reduce((acc, curr, i) => {
    const lastIndex = projects.length - 1

    const previous = curr.order === order - 1 ? curr : i === 0 ? projects[0] : null
    const next = curr.order === order + 1 ? curr : i === lastIndex ? projects[lastIndex] : null

    if (previous) acc.previous = previous
    if (next) acc.next = next

    return acc
  }, {})

const ProjectNavigationModule = ({
  order,
  // previousLink,
  // previousName,
  // previousTags,
  // nextLink,
  // nextName,
  // nextTags,
}) => {
  const projects = useFrontmatters()
  const { next, previous } = getNavigation(projects, order)

  return (
    <div className="cf">
      <div className="w-100 mt0 cf b--light-gray bt">
        <div className="mw8-l center">
          <div className="fl w-100 w-50-l">
            <div className="fr w-100 br-l b--light-gray pa5 relative">
              <div>
                <a className="underline-hover link black" href={`${next.path}`}>
                  {next.title}
                </a>
              </div>
              <div>
                {next.tags &&
                  next.tags.map((tag, i) => {
                    const last = next.tags.length - 1 === i
                    const previousLast = next.tags.length - 2 === i
                    return (
                      <span key={i} className="o-50 ma0 f7">
                        {tag.name}
                        {last ? '' : previousLast ? ', and ' : ', '}
                      </span>
                    )
                  })}
              </div>
              <div className="fl absolute left-1 left-0-l half-top f3">
                <a className="link near-black" href={`${next.path}`}>
                  &#8592;
                </a>
              </div>
            </div>
          </div>

          <div className="fl w-100 w-50-l">
            <div className="w-100 fl pa5 relative bt bn-l b--light-gray">
              <div>
                <a className="underline-hover link black" href={`${previous.path}`}>
                  {previous.title}
                </a>
              </div>
              <div>
                {previous.tags &&
                  previous.tags.map((tag, i) => {
                    const last = previous.tags.length - 1 === i
                    const previousLast = previous.tags.length - 2 === i
                    return (
                      <span key={i} className="o-50 ma0 f7">
                        {tag.name}
                        {last ? '' : previousLast ? ', and ' : ', '}
                      </span>
                    )
                  })}
              </div>
              <div className="fr absolute right-1 right-0-l half-top f3">
                <a className="link near-black" href={`${previous.path}`}>
                  &#8594;
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectNavigationModule
