import React from 'react'

const TextHalfImageModuleLeft = ({ title, text, full, video, image, caption, poster }) => (
  <div className="mw7-m mw8-l center ph3-ns pv3-ns mt4-ns ">
    <div className="cf">
      <div className="fr w-100 w-50-l mb4 mb5-m">
        <div className="w-two-thirds-l ph3 ph0-l ph0-m pt4 pt0-ns fr-l">
          <h3 className="mt0 normal">{title}</h3>
          {text}
        </div>
      </div>
      <div className="w-100 w-60-ns w-50-l mb4">
        {image && (
          <img className="w-100 w-80-ns w-two-thirds-l ba b--light-gray" src={image} alt="" />
        )}

        {video && (
          <video
            className="w-100 w-80-ns w-two-thirds-l"
            src={video}
            poster={poster}
            controls
            loop
          />
        )}
        {caption && <div className="pt3 pl3 pl0-ns f7 silver">{caption}</div>}
      </div>
    </div>
  </div>
)

export default TextHalfImageModuleLeft
