import React from 'react'

const ImagesModule = ({ image, image2, caption, caption2 }) => (
  <div className="cf">
    <div className="mw8-l center">
      <div className="w-100 mb4 mb5-l cf">
        <div className="fl w-100 w-50-l ph3 ph0-ns">
          <div className="fr w-100 w-50-ns w-two-thirds-l mr4-ns pt0-ns pt4">
            <img src={image} className="ba b--light-gray" alt="" />
            <div className="pa5 pa4-ns pl3 pt3 f7 pt3-m pl0-m pa0-l mt3-l silver">{caption}</div>
          </div>
        </div>
        <div className="fl w-100 w-50-l tl pt4-ns ph3 ph0-ns">
          <div className="fr w-100 w-50-m ml3-ns fl-m w-two-thirds-l ml6-l mt6-l">
            <img src={image2} className="ba b--light-gray" alt="" />
            <div className="pa4 pl3 pt3 f7 pt3-m pl0-m pr0-m pa0-l mt3-l silver">{caption2}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ImagesModule
