import React from 'react'

export default class SingleAnimationAspectRatioContentModule extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selected: 0,
    }
  }

  render() {
    const {
      images,
    } = this.props

    const {
      selected
    } = this.state

    setTimeout(() => {
      this.setState({ selected: selected < images.length ? selected + 1 : 0})
    }, 5000)

    return(
      <div className="bg-light-gray ph3 pv4 pv5-ns">
        <div className="mw7-m mw8-l center">
          <div className="relative slideshow-js">
            {
              images.map((image, index) => {
                return (
                  <img key={index} src={ image } className={`${selected === index ? '' : 'aspect-ratio--object'} offering__carousel-node`} width="100%" style={{ opacity: selected === index ? 1 : 0 }} alt="single animation" />
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}
