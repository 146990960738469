import React from 'react'

const FullTextModule = ({ title, text }) => (
  <div className="section-color">
    <div className="mw7-m mw8-l center ph3 pv4 pv5-ns">
      <div className="cf mh-2-m mh-3-l white">
        <div className="fl w-third-l ph2-m ph3-l ">
          <h3 className="mt0 normal mono">{ title }</h3>
        </div>
        <div className="fl w-two-thirds-l ph2-m ph3-l" dangerouslySetInnerHTML={{__html: text}}>
        </div>
      </div>
    </div>
  </div>
)

export default FullTextModule
