import React from 'react'
import { Waypoint } from 'react-waypoint'

export default class MockupTabletSmallModule extends React.Component {
  constructor(props) {
    super(props)
    this.vidRef = React.createRef()
  }

  playVideo = () => {
    this.vidRef.current.play()
  }

  stopVideo = () => {
    this.vidRef.current.pause()
    this.vidRef.current.currentTime = 0
  }

  render() {
    const { image, video, image2, image3, caption2, caption3, poster } = this.props
    return (
      <div className="mw7-m mw8-l center ph3 pv4 pv5-ns">
        <div className="cf">
          <div className="fl w-100 w-70-m w-50-l tc mh6-m pa4">
            <div className="center" style={{ maxWidth: '100%' }}>
              <div className="shadow-2 br8">
                <div className="bg-white br4 pa4 br6-l">
                  {image && <img src={image} alt="" />}
                  {video && (
                    <div>
                      <Waypoint
                        onEnter={this.playVideo.bind(this)}
                        onLeave={this.stopVideo.bind(this)}
                      />
                      <video
                        ref={this.vidRef}
                        className="w-100 ba b--light-gray"
                        src={video}
                        poster={poster}
                        controls
                        muted
                        loop
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="fl w-100 w-70-m w-50-l mh6-m ph4">
            <div className="fr-l">
              <img className="center w-70-l" src={image2} alt="" />
              <p className="ph5-ns f7 silver">{caption2}</p>
              <img className="center w-70-l" src={image3} alt="" />
              <p className="ph5-ns f7 silver">{caption3}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
