import React from 'react'

const BorderSingleImageModule = ({ image, video, youtube, maxWidth, caption, poster }) => (
  <div className="bg-light-gray">
    <div className="mw7-m mw8-l center">
      <div className="ph3 pv4 pv5-ns">
        {
          image &&
          (
            maxWidth > 0
            ? (
              <img src={image} alt="" className={`${maxWidth} mw-100`} style={{ margin: 'auto' }} />
            )
            : (
              <img src={image} alt="" width="100%" />
            )
          )
        }
        {
          video &&
          <video className="w-100" src={video} poster={poster} controls loop></video>
        }
        {
          caption &&
          (
            maxWidth > 0
            ? (
              <div className="pt3 pl3 pl0-ns tc f7 silver">{caption}</div>
            )
            : (
              <div className="pt3 pl3 pl0-ns f7 silver">{caption}</div>
            )
          )
        }
      </div>
    </div>
  </div>
)

export default BorderSingleImageModule
