import React from 'react'
import { graphql } from 'gatsby'
import WorkHeader from '../components/WorkHeader'
import BrowserImageModule from '../components/BrowserImageModule'
import FullTextModule from '../components/FullTextModule'
import BorderSingleImageModule from '../components/BorderSingleImageModule'
import WhiteSingleImageModule from '../components/WhiteSingleImageModule'
import LittleTextModule from '../components/LittleTextModule'
import MockupPhoneModule from '../components/MockupPhoneModule'
import LinkModule from '../components/LinkModule'
import LinkModuleLeftRight from '../components/LinkModuleLeftRight'
import LinkRightModule from '../components/LinkRightModule'
import ProjectFooterModule from '../components/ProjectFooterModule'
import ProjectNavigationModule from '../components/ProjectNavigationModule'
import TextHalfImageModule from '../components/TextHalfImageModule'
import TextHalfImageModuleLeft from '../components/TextHalfImageModuleLeft'
import ImagesModule from '../components/ImagesModule'
import TextImageDetailModule from '../components/TextImageDetailModule'
import TextImageDetailLeftModule from '../components/TextImageDetailLeftModule'
import MockupTabletSmallModule from '../components/MockupTabletSmallModule'
import FullSketchModule from '../components/FullSketchModule'
import MockupTabletModule from '../components/MockupTabletModule'
import SvgModule from '../components/SvgModule'
import TextWhiteModule from '../components/TextWhiteModule'
import SingleAnimationAspectRatioContentModule from '../components/SingleAnimationAspectRatioContentModule'
import TwoImagesSameLineModule from '../components/TwoImagesSameLineModule'
import Layout from '../components/layout'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter } = markdownRemark

  return (
    <Layout
      type={'project'}
      primary={frontmatter.primary}
      secondary={frontmatter.secondary}
      title={frontmatter.title}
      description={frontmatter.description}
    >
      <style
        dangerouslySetInnerHTML={{
          __html:
            '.primary { color: #' +
            frontmatter.primary +
            ' }' +
            '.secondary-hover:hover { color: #' +
            frontmatter.secondary +
            ' }' +
            '.bg-primary { background-color: #' +
            frontmatter.primary +
            ' }' +
            '.b--primary { border-color: #' +
            frontmatter.primary +
            ' }' +
            '.b--secondary-hover:hover { border-color: #' +
            frontmatter.secondary +
            ' }',
        }}
      />
      <WorkHeader
        title={frontmatter.title}
        client={frontmatter.client}
        year={frontmatter.year}
        primary={frontmatter.primary}
        secondary={frontmatter.secondary}
        tags={frontmatter.tags}
        description={frontmatter.description}
      />
      {frontmatter.modules &&
        frontmatter.modules.map((module, i) => {
          if (module.properties) {
            if (module.properties.name === 'browser_image_module') {
              const image = module.properties.image || null
              const video = module.properties.video || null
              const youtube = module.properties.youtube || null
              const poster = module.properties.poster || null
              const caption = module.properties.caption || null

              return (
                <BrowserImageModule
                  image={image}
                  video={video}
                  youtube={youtube}
                  poster={poster}
                  caption={caption}
                  key={i}
                />
              )
            } else if (module.properties.name === 'full_text_module') {
              const title = module.properties.title
              const text = module.properties.text
              return <FullTextModule title={title} text={text} key={i} />
            } else if (module.properties.name === 'border_single_image_module') {
              const image = module.properties.image || null
              const video = module.properties.video || null
              const youtube = module.properties.youtube || null
              const maxWidth = module.properties.maxWidth || null
              const caption = module.properties.caption || null
              const poster = module.properties.poster || null
              return (
                <BorderSingleImageModule
                  image={image}
                  video={video}
                  youtube={youtube}
                  maxWidth={maxWidth}
                  caption={caption}
                  poster={poster}
                  key={i}
                />
              )
            } else if (module.properties.name === 'white_single_image_module') {
              const image = module.properties.image || null
              const image2 = module.properties.image2 || null
              const video = module.properties.video || null
              const title = module.properties.title || null
              const poster = module.properties.poster || null
              return (
                <WhiteSingleImageModule
                  image={image}
                  image2={image2}
                  video={video}
                  title={title}
                  poster={poster}
                  key={i}
                />
              )
            } else if (module.properties.name === 'little_text_module') {
              const title = module.properties.title || null
              const text = module.properties.text || null
              return (
                <LittleTextModule
                  title={title}
                  text={text}
                  primary={frontmatter.primary}
                  secondary={frontmatter.secondary}
                  key={i}
                />
              )
            } else if (module.properties.name === 'mockup_phone') {
              const title = module.properties.title || null
              const text = module.properties.text || null
              const image = module.properties.image || null
              const video = module.properties.video || null
              const poster = module.properties.poster || null
              const caption = module.properties.caption || null
              return (
                <MockupPhoneModule
                  title={title}
                  text={text}
                  image={image}
                  video={video}
                  poster={poster}
                  caption={caption}
                  key={i}
                />
              )
            } else if (module.properties.name === 'link_module') {
              const link = module.properties.link || null
              const display = module.properties.display || null
              return <LinkModule link={link} display={display} key={i} />
            } else if (module.properties.name === 'link_module_right') {
              const text = module.properties.text || null
              const link = module.properties.link || null
              const display = module.properties.display || null
              return <LinkRightModule text={text} link={link} display={display} key={i} />
            } else if (module.properties.name === 'link_module_left_right') {
              const link = module.properties.link || null
              const display = module.properties.display || null
              const link2 = module.properties.link2 || null
              const display2 = module.properties.display2 || null
              return (
                <LinkModuleLeftRight
                  link={link}
                  display={display}
                  link2={link2}
                  display2={display2}
                  key={i}
                />
              )
            } else if (module.properties.name === 'project_footer') {
              const team = module.properties.team || null
              const press = module.properties.press || null
              const awards = module.properties.awards || null
              const services = module.properties.services || null

              return (
                <ProjectFooterModule
                  team={team}
                  services={services}
                  press={press}
                  awards={awards}
                  key={i}
                />
              )
            } else if (module.properties.name === 'project_navigation') {
              const previous = module.properties.previous || null
              const next = module.properties.next || null
              const previousLink = previous?.link || null
              const previousName = previous?.name || null
              const previousTags = previous?.tags || null
              const nextLink = next?.link || null
              const nextName = next?.name || null
              const nextTags = next?.tags || null
              const order = frontmatter.order || null

              return (
                <ProjectNavigationModule
                  order={order}
                  previousLink={previousLink}
                  previousName={previousName}
                  previousTags={previousTags}
                  nextLink={nextLink}
                  nextName={nextName}
                  nextTags={nextTags}
                  key={i}
                />
              )
            } else if (module.properties.name === 'text_half_image_module') {
              const full = module.properties.full || null
              const text = module.properties.text || null
              const image = module.properties.image || null

              const video = module.properties.video || null
              const poster = module.properties.poster || null
              const caption = module.properties.caption || null
              return (
                <TextHalfImageModule
                  image={image}
                  text={text}
                  full={full}
                  video={video}
                  caption={caption}
                  poster={poster}
                  key={i}
                />
              )
            } else if (module.properties.name === 'text_image_half_module_left') {
              const full = module.properties.full || null
              const title = module.properties.title || null
              const text = module.properties.text || null
              const image = module.properties.image || null

              const video = module.properties.video || null
              const poster = module.properties.poster || null
              const caption = module.properties.caption || null
              return (
                <TextHalfImageModuleLeft
                  image={image}
                  title={title}
                  text={text}
                  full={full}
                  video={video}
                  caption={caption}
                  poster={poster}
                  key={i}
                />
              )
            } else if (module.properties.name === 'images_module') {
              const image = module.properties.image || null
              const image2 = module.properties.image2 || null
              const caption = module.properties.caption || null
              const caption2 = module.properties.caption2 || null
              return (
                <ImagesModule
                  image={image}
                  image2={image2}
                  caption={caption}
                  caption2={caption2}
                  key={i}
                />
              )
            } else if (module.properties.name === 'text_image_detail_module') {
              const text = module.properties.text || null
              const image = module.properties.image || null
              const video = module.properties.video || null
              const poster = module.properties.poster || null
              const caption = module.properties.caption || null
              return (
                <TextImageDetailModule
                  text={text}
                  image={image}
                  video={video}
                  poster={poster}
                  caption={caption}
                  key={i}
                />
              )
            } else if (module.properties.name === 'text_image_detail_module_left') {
              const title = module.properties.title || null
              const text = module.properties.text || null
              const image = module.properties.image || null
              const full = module.properties.full || null
              const caption = module.properties.caption || null
              return (
                <TextImageDetailLeftModule
                  text={text}
                  image={image}
                  full={full}
                  title={title}
                  caption={caption}
                  key={i}
                />
              )
            } else if (module.properties.name === 'mockup_tablet_small') {
              const video = module.properties.video || null
              const image = module.properties.image || null
              const image2 = module.properties.image2 || null
              const image3 = module.properties.image3 || null
              const caption2 = module.properties.caption2 || null
              const caption3 = module.properties.caption3 || null
              const poster = module.properties.poster || null

              return (
                <MockupTabletSmallModule
                  video={video}
                  image={image}
                  image2={image2}
                  image3={image3}
                  caption2={caption2}
                  caption3={caption3}
                  poster={poster}
                  key={i}
                />
              )
            } else if (module.properties.name === 'full_sketch_module') {
              const title = module.properties.title || null
              const text = module.properties.text || null
              const image = module.properties.image || null
              const caption = module.properties.caption || null
              return (
                <FullSketchModule
                  title={title}
                  text={text}
                  image={image}
                  caption={caption}
                  key={i}
                />
              )
            } else if (module.properties.name === 'mockup_tablet') {
              const video = module.properties.video || null
              const image = module.properties.image || null
              const poster = module.properties.poster || null
              return <MockupTabletModule image={image} video={video} poster={poster} key={i} />
            } else if (module.properties.name === 'svg_module') {
              const image = module.properties.image || null
              return <SvgModule image={image} key={i} />
            } else if (module.properties.name === 'text_white_module') {
              const title = module.properties.title || null
              const text = module.properties.text || null
              return <TextWhiteModule title={title} text={text} key={i} />
            } else if (
              module.properties.name === 'single_image_animation_module_aspectratio_content'
            ) {
              const image = module.properties.image || null
              const image2 = module.properties.image2 || null
              const image3 = module.properties.image3 || null
              const images = [image, image2, image3]
              return <SingleAnimationAspectRatioContentModule images={images} key={i} />
            } else if (module.properties.name === 'two_images_on_same_line') {
              const image = module.properties.image || null
              const image2 = module.properties.image2 || null
              const caption = module.properties.caption || null
              const caption2 = module.properties.caption2 || null
              return (
                <TwoImagesSameLineModule
                  image={image}
                  image2={image2}
                  caption={caption}
                  caption2={caption2}
                  key={i}
                />
              )
            }
          }
          return null
        })}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($pagePath: String!) {
    markdownRemark(frontmatter: { path: { eq: $pagePath } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        order
        client {
          name
          logo
        }
        year
        primary
        secondary
        tags {
          name
        }
        description
        modules {
          properties {
            name
            image
            image2
            image3
            video
            maxWidth
            poster
            caption
            caption2
            caption3
            link
            link2
            display
            display2
            title
            text
            team {
              name
            }
            services {
              name
            }
            press {
              name
              text
              link
            }
            awards {
              name
            }
            previous {
              name
              tags {
                name
              }
              link
            }
            next {
              name
              tags {
                name
              }
              link
            }
          }
        }
      }
    }
  }
`
