import React from 'react'

const LinkModule = ({ link, display }) => (
  <div className="mw7-m mw8-l center ph3">
    <div className="cf">
      <div className="w-100 w-two-thirds-m fl-l w-50-l pv4 pv5-ns center">
          <div className="w-100 w-two-thirds-l center">
              <a href={ link } target="_blank" without="true" rel="noopener noreferrer" className="no-underline c-inherit">
                  <div className="tc pv5 ba bw4 f6 f5-m f5-l primary secondary-hover b--primary b--secondary-hover" style={{transition: '0.5s all ease-in-out' }}>{ display } &#8599;
                  </div>
              </a>
          </div>
      </div>
    </div>
  </div>
)

export default LinkModule
