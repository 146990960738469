import React from 'react'

const TwoImagesSameLineModule = ({ image, image2, caption, caption2 }) => (
  <div className="flex flex-column flex-row-l justify-center items-center bg-light-gray pa4">
    <div className="w-33-l w-100 mr4-l mt2">
      <img src={ image } className="w-100" style={{ maxWidth: '300px', margin: 'auto' }} alt=""/>
      {
        caption &&
        <div className="pt3 pl3 pl0-ns tc f7 silver">{ caption }</div>
      }
    </div>

    <div className="w-33-l w-100 ml4-l mt2">
      <img src={ image2 } className="w-100" style={{ maxWidth: '300px', margin: 'auto' }} alt=""/>
      {
        caption2 &&
        <div className="pt3 pl3 pl0-ns tc f7 silver">{ caption2 }</div>
      }
    </div>

  </div>
)

export default TwoImagesSameLineModule
