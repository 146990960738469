import React from 'react'

const TextImageDetailModule = ({ text, image, video, poster, caption }) => (
  <div className="mw7-m mw8-l center ph3-ns pv3-ns mt4-ns ">
    <div className="cf">
      <div className="fl w-100 w-50-l mb4">
        <div className="w-two-thirds-l ph3 ph0-l ph0-m pt4 pt0-ns">
          {text}
        </div>
      </div>
      <div className="w-100 w-60-ns w-50-l fl-ns ml6-m mb4">
        {
          image &&
          <img className="w-100 ba b--light-gray" src={image} alt="text-detail"/>
        }

        {
          video &&
          <video className="w-100 ba b--light-gray" src={ video } poster={ poster } controls loop></video>
        }

        {
          caption &&
          <div className="pt3 pl3 pl0-ns f7 silver">{caption}</div>
        }
      </div>
    </div>
  </div>
)

export default TextImageDetailModule
