import React from 'react'

const FullSketchModule = ({ title, text, image, caption }) => (
  <div className="bg-light-gray">
    <div className="mw7-m mw8-l center ph3 pv4 pv5-ns">
      <div className="cf mh-2-m mh-3-l">
        <div className="fl w-third-l ph2-m ph3-l ">
          <h3 className="mt0 normal">{title}</h3>
        </div>
        <div className="fl ph2-m ph3-l" dangerouslySetInnerHTML={{ __html: text }}></div>
        <div className="ph3">
          <img src={image} width="100%" alt="" />
          {caption && (
            <div className="pa3 tc b dark-grey" dangerouslySetInnerHTML={{ __html: caption }} />
          )}
        </div>
      </div>
    </div>
  </div>
)

export default FullSketchModule
