import React from 'react'

const ProjectFooterModule = ({ team, press, services }) => (
  <div className="cf ph3 ph4-ns pv4 pv6-l bt b--black-10 black-70">
    <div className="mw8-l center">
      <div className="fl cf mb4 w-100 w-third-l">
        <div className="fl w-25">
          <div className="f6 dib">Team</div>
        </div>
        <div className="fl w-75">
          {
            team && team.map((t,i) => {
              return (
                <div key={i}>
                  {
                    t.link
                    ? (
                      <a href= {t.link} target="_blank" without rel="noopener noreferrer" className="w-100 f6 dib pr2 primary-hover link underline primary">{ t.name }</a>
                    ) : (
                      <div className="w-100 f6 dib pr2 primary-hover">{ t.name }</div>
                    )
                  }
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="fl cf mb4 w-100 w-third-l">
       {
         !press
         ? (
           <div className="fl w-25">
            <div className="f6 dib"></div>
          </div>
        )
        : (
          <div className="fl w-25">
            <div className="f6 dib">Press</div>
          </div>
        )
      }
        <div className="fl w-75">
          {
            press && press.map((p,i) => {
              return (
                <div key={i}>
                  {
                    p.link
                      ? (
                        <div>
                          <a href={ p.link } target="_blank" without rel="noopener noreferrer" className="w-100 f6 dib primary secondary-hover link underline primary">{ p.name } &#8599;</a>
                          <div className="w-100 f6 di pr2">{ p.text }</div>
                        </div>
                      ) : (
                        <div className="w-100 f6 dib pr2 primary-hover">{ p.name }</div>
                      )
                  }
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="fl cf mb4 w-100 w-third-l">
        <div className="fl w-25">
          <div className="f6 dib">Services</div>
        </div>
        <div className="fl w-75">
          {
            services && services.map((service, i) => {
              return (
                <div key={i} className="w-100 f6 dib pr2 primary-hover">{ service.name }</div>
              )
            })
          }
        </div>
      </div>
    </div>
  </div>
)

export default ProjectFooterModule
