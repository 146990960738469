import React from 'react'
import { Waypoint } from 'react-waypoint'

export default class MockupPhoneModule extends React.Component {
  constructor(props) {
    super(props)
    this.vidRef = React.createRef()
  }

  playVideo = () => {
    this.vidRef.current.play()
  }

  stopVideo = () => {
    this.vidRef.current.pause()
    this.vidRef.current.currentTime = 0
  }

  render() {
    const { title, text, image, video, poster, caption } = this.props
    return (
      <div className="mw7-m mw8-l center ph3 pv4 pv5-ns">
        <div className="cf">
          <div className="fl w-50-l mb4 pt5-l">
            <div className="w-two-thirds-l">
              <h3 className="mt0 normal">{title}</h3>
              <p className="ma0" dangerouslySetInnerHTML={{ __html: text }}></p>
            </div>
          </div>

          <div className="fl w-100 w-50-l bg-light-gray tc pa4 pa5-ns">
            <div className="center" style={{ maxWidth: '20rem' }}>
              <div className="shadow-2 br8">
                <div className="bg-white pv5 ph3 br8">
                  {image && <img src={image} alt="" />}
                  {video && (
                    <div>
                      <Waypoint
                        onEnter={this.playVideo.bind(this)}
                        onLeave={this.stopVideo.bind(this)}
                      />
                      <video
                        ref={this.vidRef}
                        className="w-100 ba b--light-gray"
                        src={video}
                        poster={poster}
                        controls
                        muted
                        loop
                      />
                    </div>
                  )}
                </div>
              </div>
              {caption && (
                <div className="pa3 tc b dark-grey" dangerouslySetInnerHTML={{ __html: caption }} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
