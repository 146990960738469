import React from 'react'

const SvgModule = ({ image }) => (
  <div className="mw7-m mw8-l center pb4 pb0-ns ph3 pt4 pt5-ns">
    <div className="cf nb5-l">
      <div className="fl w-100 w-50-l">
        <div className="center fr-l w-two-thirds-l bg-primary relative aspect-ratio--6x4">
          <div className="aspect-ratio--object flex items-center justify-center center">
            <img src={ image } className="w-50 h-50" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default SvgModule
