import React from 'react'
import { isEmpty } from 'lodash'

const WorkHeader = ({ title, client, year, description, primary, secondary, tags }) => (
  <div
    className="white flex-l flex-wrap-l justify-center-l vh-100-header-ns"
    style={{ background: `linear-gradient(135deg, #${primary}, #${secondary})` }}
  >
    <div className="w-100-l self-end-l bt-l b--white-20">
      <div className="pt4 pt0-l">
        <div className="mw7-m mw8-l center ph3">
          <div className="cf">
            <div className="fl-l w-50-l">
              <div className="pv5-l pr5-l">
                <p className="mt0 mb2">
                  {year} | {isEmpty(client) ? '' : client[0].name}
                </p>
                <h1 className="mt0 mb2 lh-title normal">{title}</h1>
                <p className="ma0 o-50">
                  {tags &&
                    tags.map((tag, i) => {
                      const last = tags.length - 1 === i
                      const previousLast = tags.length - 2 === i
                      return (
                        <span key={i}>
                          {`${tag.name}${last ? '' : previousLast ? ', and ' : ', '}`}{' '}
                        </span>
                      )
                    })}
                </p>
              </div>
            </div>
            <div className="fl-l w-50-l bl-l b--white-20">
              <div className="pv5 pv5-l pl5-l">
                <p className="ma0" dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default WorkHeader
