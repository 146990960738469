import React from 'react'
import { Waypoint } from 'react-waypoint'

export default class WhiteSingleImageModule extends React.Component {
  constructor(props) {
    super(props)
    this.vidRef = React.createRef()
  }

  playVideo = el => {
    this.vidRef.current.play()
  }

  stopVideo = () => {
    this.vidRef.current.pause()
    this.vidRef.current.currentTime = 0
  }

  render() {
    const { image, image2, video, poster, title } = this.props
    return (
      <div className="bg-white">
        <div className="mw7-m mw8-l center">
          <div className="ph3 pv4">
            {image2 && <img className="pb2 pb2-ns" src={image2} alt="" width="" />}
            {image && <img className="pb2" src={image} alt="" width="" />}
            {video && (
              <div>
                <Waypoint onEnter={this.playVideo.bind(this)} onLeave={this.stopVideo.bind(this)} />
                <video
                  ref={this.vidRef}
                  className="w-100"
                  src={video}
                  poster={poster}
                  controls
                  muted
                  loop
                ></video>
              </div>
            )}
            {title && (
              <div
                className="pa4 pl3 pt3 f7 pt3-m pl0-m pr0-m pa0-l mt3-l silver pb3"
                dangerouslySetInnerHTML={{ __html: title }}
              ></div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
