import React from 'react'

const LinkModuleLeftRight = ({ link, display, link2, display2 }) => (
  <div className="center ph3 pv4 mw7-m pb0-m pv2-l mw8-l pv5-ns">
    <div className="cf">
      <div className="w-100 w-two-thirds-m fl-l w-50-l pv4 pv5-ns center">
          <div className="w-100 w-two-thirds-l center pt6 pt5-m pt0-l">
              <a href={ link } target="_blank" without="true" rel="noopener noreferrer" className="no-underline c-inherit">
                  <div className="tc pv5 ba bw4 f6 f5-m f5-l primary secondary-hover b--primary b--secondary-hover" style={{ transition: '0.5s all ease-in-out' }}>{ display } &#8599;
                  </div>
              </a>
          </div>
      </div>

      <div className="w-100 w-two-thirds-m fl-l w-50-l pv4 pv5-ns center">
          <div className="w-100 w-two-thirds-l center pt6 pt5-m pt0-l">
              <a href={ link2 } target="_blank" without="true" rel="noopener noreferrer" className="no-underline c-inherit">
                  <div className="tc pv5 ba bw4 f6 f5-m f5-l primary secondary-hover b--primary b--secondary-hover" style={{ transition: '0.5s all ease-in-out' }}>{ display2 } &#8599;
                  </div>
              </a>
          </div>
      </div>
    </div>
  </div>
)

export default LinkModuleLeftRight
