import React from 'react'

const TextWhiteModule = ({ text, title }) => (
    <div className="mw7-m mw8-l center ph3">
      <div className="cf">
        <div className="w-100-l pv4 pv5-ns">
          <h3 className="mt0 normal">{ title }</h3>
          <div dangerouslySetInnerHTML={{__html: text}}></div>
        </div>
      </div>
    </div>
)

export default TextWhiteModule
