import React from 'react'
import { Waypoint } from 'react-waypoint'

export default class MockupTabletModule extends React.Component {
  constructor(props) {
    super(props)
    this.vidRef = React.createRef()
  }

  playVideo = () => {
    this.vidRef.current.play()
  }

  stopVideo = () => {
    this.vidRef.current.pause()
    this.vidRef.current.currentTime = 0
  }

  render() {
    const { image, video, poster } = this.props
    return (
      <div className="bg-light-gray">
        <div className="mw7-m mw8-l center ph3 pv4 pv5-ns">
          <div className="br4 br8-l shadow-2">
            <div className="bg-white br4 pa3 pa4-m pa5-l br8-l">
              {image && <img src={image} alt="" />}
              {video && (
                <div>
                  <Waypoint
                    onEnter={this.playVideo.bind(this)}
                    onLeave={this.stopVideo.bind(this)}
                  />
                  <video
                    ref={this.vidRef}
                    className="w-100 ba b--light-gray"
                    src={video}
                    poster={poster}
                    controls
                    muted
                    loop
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
