import React from 'react'
import { Waypoint } from 'react-waypoint'

export default class BrowserImageModule extends React.Component {
  constructor(props) {
    super(props)
    this.vidRef = React.createRef()
  }

  playVideo = () => {
    this.vidRef.current.play()
  }

  stopVideo = () => {
    this.vidRef.current.pause()
    this.vidRef.current.currentTime = 0
  }

  render() {
    const { image, video, youtube, poster, caption } = this.props
    return (
      <div className="bg-light-gray">
        <div className="mw7-m mw8-l center">
          <div className="ph3 pv4 pv5-ns">
            <div className="shadow-2 br1 br2-ns br--top-ns">
              <div className="bg-mid-gray br1 br2-ns br--top-ns pa1 pa2-m pt3-l"></div>
              {image && (
                <img src={image} alt={image} className="br1 br2-ns br--bottom-ns" width="100%" />
              )}
              {video &&
                (youtube ? (
                  <div className="w-100 aspect-ratio aspect-ratio--16x9 overflow-hidden relative">
                    <iframe
                      className="w-100 br1 br2-ns br--bottom-ns"
                      width="100%"
                      src={`https://www.youtube.com/embed/${video}?t=0&amp;rel=0&amp;controls=0&amp;showinfo=0`}
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                      title="Browser Image"
                    ></iframe>
                  </div>
                ) : (
                  <div>
                    <Waypoint
                      onEnter={this.playVideo.bind(this)}
                      onLeave={this.stopVideo.bind(this)}
                    />
                    <video
                      ref={this.vidRef}
                      className="w-100 br1 br2-ns br--bottom-ns"
                      src={video}
                      poster={poster}
                      controls
                      muted
                      loop
                    ></video>
                  </div>
                ))}
            </div>
            {caption && <div className="pa3 tc b dark-grey" dangerouslySetInnerHTML={{ __html: caption }} />}
          </div>
        </div>
      </div>
    )
  }
}
